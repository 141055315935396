<template>
    <div>
        <div class="bg-white rounded-lg p-5">
            <UnBindSocialMedia :haveBindSocialMedia="bindSocialMedias" :unBindSocialMedia="unBindSocialMedias" @callback="setDefault" />
        </div>
        <div class="bg-white rounded-lg mb-5 mt-10 p-5">
            <div class="flex">
                <h2 class="font-medium md:text-2xl text-base md:mb-10 mb-3 flex-1">簡訊通知</h2>
                <el-switch
                    v-model="form.openSMS_Notice"
                    active-color="#FF5733"
                    inactive-color="#e5e5e5"
                    :active-value="1"
                    :inactive-value="0"
                    @change="updateGetSMSNotice">
                </el-switch>
            </div>
            <p class="text-gray-300 md:text-base text-xs my-2">會員向您發起的預訂時，會以傳送簡訊的方式通知您，您無須 為簡訊負擔額外的費用。</p>
        </div>
        <div class="bg-white rounded-lg mb-5 mt-10 p-5">
            <div class="flex">
                <h2 class="font-medium md:text-2xl text-base md:mb-10 mb-3 flex-1">CityAI 智能客服</h2>
                <el-switch
                    v-model="form.enableCityAi"
                    active-color="#FF5733"
                    inactive-color="#e5e5e5"
                    :active-value="1"
                    :inactive-value="0"
                    @change="setCityAi">
                </el-switch>
            </div>
            <p class="text-gray-300 md:text-base text-xs my-2">
                為提升用戶體驗及訊息回應效率，當開啟 CityAI 智能客服後， 機器人將協助您回覆會員，如使用諮詢與回應預訂等問題。 CityAI
                智能客服判定會員為潛在消費者後，會自動通知平台專員 聯絡您，由您自行決定並回覆是否接受訂單與答覆更詳細的資 訊。請記得，CityAI
                智能客服無法代替您處理任何訂單，且當您 上線時，CityAI 智能客服將暫停運作。您可隨時啟用或關閉 CityAI 智能客服功能。
            </p>
        </div>
    </div>
</template>
<script>
// 取消綁定第三方組件
import UnBindSocialMedia from "@/components/UnBindSocialMedia.vue";
export default {
    components: {
        UnBindSocialMedia,
    },
    data() {
        return {
            form: {
                openSMS_Notice: true,
            },
            // 已綁定第三方帳號資料
            bindSocialMedias: {},
            // 未綁定第三方帳號資料
            unBindSocialMedias: {},
        };
    },
    methods: {
        async setDefault() {
            try {
                const { data } = await this.$api.GetUserDataApi(this.$route.params.id);
                const bindSocialMedia = {};
                const unBindSocialMedia = {};
                // 判斷第三方登入使用者是否有資料
                const filterSocialMedias = ["facebook_user", "google_user", "line_user"];
                filterSocialMedias.forEach((socialMedia, index) => {
                    if (!this.$isEmpty(data[socialMedia])) {
                        bindSocialMedia[socialMedia] = data[socialMedia];
                        bindSocialMedia[socialMedia]["index"] = index;
                    }
                });
                filterSocialMedias.forEach((socialMedia, index) => {
                    if (this.$isEmpty(data[socialMedia])) {
                        unBindSocialMedia[socialMedia] = {};
                        unBindSocialMedia[socialMedia]["index"] = index;
                    }
                });
                this.bindSocialMedias = bindSocialMedia;
                this.unBindSocialMedias = unBindSocialMedia;
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得個人資料失敗",
                });
            }
        },
        // 取得是否收到簡訊通知設定值
        async getSettingsApi() {
            try {
                const {
                    data: { receiveDatingCreatedSMS, enableCityAi },
                } = await this.$api.GetSettingsApi(this.$route.params.id);
                this.$set(this.form, "openSMS_Notice", receiveDatingCreatedSMS === 0 ? 0 : 1);
                // 設定 city ai 智能客服開關
                this.$set(this.form, "enableCityAi", enableCityAi ?? 0);
            } catch (err) {
                this.$set(this.form, "openSMS_Notice", this.form.openSMS_Notice === 0 ? 0 : 1);
                this.errorCallback({ err });
            }
        },
        // 取得是否收到簡訊通知設定值
        async updateGetSMSNotice() {
            try {
                await this.$api.UpdateProfileSetting(this.$route.params.id, { receiveDatingCreatedSMS: this.form.openSMS_Notice });
            } catch (err) {
                this.$set(this.form, "openSMS_Notice", this.form.openSMS_Notice === 1 ? 0 : 1);
                this.errorCallback({ err });
            }
        },
        // 設定是否啟用 city ai 智能客服
        async setCityAi() {
            try {
                await this.$api.SetCtiyAiApi(this.$route.params.id, this.form.enableCityAi);
            } catch (err) {
                // 更新失敗時回覆預設值
                this.$set(this.form, "enableCityAi", this.form.enableCityAi === 1 ? 0 : 1);
                this.errorCallback({ err });
            }
        },
    },
    created() {
        this.getSettingsApi();
    },
    activated() {
        this.getSettingsApi();
    },
    // render(h) {
    //     return h("UnBindSocialMedia", {});
    // }
};
</script>
