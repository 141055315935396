<template>
    <div>
        <video v-if="this.videoLink !== null" id="video" refs="video" :src="this.videoLink" type="video/mp4" crossOrigin="Anonymous" controls></video>
        <img v-if="cropped !== null" class="w-[300px]" :src="this.cropped" alt="" />
        <div v-if="this.videoLink !== null" class="flex w-full items-center">
            <button v-if="this.videoLink !== null" class="blue-btn mr-2" @click="croppie()">設定影片封面照</button>
            <button v-if="this.videoLink !== null && cropped !== null" class="blue-btn" @click="checkCoverPhoto()">確認封面照</button>
        </div>
        <el-upload
            v-if="videoFile === null"
            ref="upload"
            class="mx-2"
            :list-type="'text'"
            :on-change="handleChange"
            :auto-upload="false"
            :limit="1"
            drag
            action=""
            :on-exceed="videoOverLimit"
            accept=".mp4,.mov,.MOV">
            <div slot="default" class="relative">
                <!-- 呈現字串得上傳框 -->
                <div class="w-32 h-32 border-dashed border-2 rounded-lg flex items-center justify-center text-orange-600 border-red-500">
                    <i class="el-icon-plus mr-1 text-orange-600" style="font-size: 20px"></i>上傳影片
                </div>
            </div>
        </el-upload>

        <!-- <button class="yellow-btn-500 sm:text-xl text-base mr-10"
                @click="uploadFile()">上傳影片</button> -->

        <el-progress v-if="videoFile !== null" :percentage="progress"></el-progress>

        <!-- 裁切時使用彈窗方式 -->
        <el-dialog
            :visible.sync="showDialog"
            :close-on-click-modal="true"
            :modal-append-to-body="true"
            :close-on-press-escape="true"
            @close="closeDialog">
            <div>
                <vue-croppie
                    ref="croppieRef"
                    :enableOrientation="true"
                    :enableResize="false"
                    :boundary="{ width: 350, height: 350 }"
                    :viewport="{ width: 330, height: 330 }"
                    @result="result">
                </vue-croppie>
                <div class="text-center">
                    <button class="yellow-btn-500 sm:text-xl text-base mr-10" @click="cropViaEvent()">確定</button>
                    <button class="red-btn-outline-600 sm:text-xl text-base" @click="closeDialog">取消</button>
                </div>
            </div>
        </el-dialog>

        <canvas v-show="false" id="canvas" ref="canvas"></canvas>
    </div>
</template>

<script>
import { storage } from "../plugins/firebase";
import { v4 as uuidv4 } from "uuid";
export default {
    props: {
        // 影片連結
        videoLink: {
            type: String,
            default: null,
        },
        // 上傳進度條
        progress: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            // 裁切圖 base64
            cropped: null,
            // 選取到的影片檔
            videoFile: null,
            // 選取到的圖片檔
            photoFile: null,
            // 判斷是否顯示彈窗
            showDialog: false,
        };
    },
    methods: {
        // 影片變更
        handleChange(file) {
            if (file.size > 50 * 1024 * 1024) {
                this.$refs.upload.clearFiles();
                this.$message({
                    type: "error",
                    message: "影片尺寸過大",
                });
                return;
            }
            this.videoFile = file.raw;
            const result = new FormData();
            result.append("video", this.videoFile, uuidv4());
            this.$emit("videoUpload", result);

            // this.uploadFile();
        },
        //移除圖片
        handleRemove() {
            this.videoFile = null;
            this.cropped = null;
            this.photoFile = null;
            // 關閉彈窗
            this.showDialog = false;
        },
        // 關閉彈窗
        closeDialog() {
            // 關閉彈窗
            this.showDialog = false;
        },
        //上傳影片超過限制數量
        videoOverLimit() {
            this.showDialog = false;
            this.$message({
                type: "error",
                message: "超過上傳影片上限",
            });
        },
        // 取得封面照
        croppie() {
            // 取得 canvas dom
            var canvas = document.getElementById("canvas");
            // 取得 video dom
            var video = document.getElementById("video");
            // 設定 canvas 顯示方式為 2d
            const ctx = canvas.getContext("2d");
            // 設定 canvas 寬度為影片寬度
            canvas.width = video.videoWidth;
            // 設定 canvas 高度為影片高度
            canvas.height = video.videoHeight;
            // 取得 影片 目前停領影格 封面照
            ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            // 將封面照檔案轉成 blob 格式
            this.$refs.canvas.toBlob((blob) => {
                // 打開彈窗
                this.showDialog = true;
                // 檔案上傳涵式
                const file = new FileReader();
                // 設定檔案
                file.readAsDataURL(blob);
                // 檔案上傳後轉譯成 base64圖檔方法
                file.onload = () => {
                    // 裁切套件設定裁切圖片函示
                    this.$refs.croppieRef.bind({
                        url: file.result,
                    });
                };
            });
            // console.log(ctx.toDataURL("image/png"));
            // video.setAttribute("crossOrigin", "anonymous");
            // video.onloadeddata = () => {
            //     ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            //     // canvas.getContext("2d").drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            //     var dataURL = ctx.toDataURL("image/png");

            //     console.log(dataURL, 1);
            // };
        },
        // 裁切事件
        cropViaEvent() {
            const options = {
                type: "blob",
                format: "jpeg",
                circle: false,
            };
            this.$refs.croppieRef.result(options);
            // 關閉彈窗
            this.showDialog = false;
        },
        /**
         * 裁切完後 結果
         * @param { type Blob(blob檔案格式)) } blob 檔案資料
         */
        result(blob) {
            // 讀取檔案資料
            const file = new FileReader();
            // 將blob檔案轉譯成 base 64
            file.readAsDataURL(blob);
            file.onload = () => {
                // 將base64 url 賦予 此變數
                this.cropped = file.result;
            };
            // 儲存裁切後檔案
            this.photoFile = blob;
        },
        // 點擊確認裁切按鈕後 結束裁切
        checkCoverPhoto() {
            // 裁切成功時要回傳一個事件
            this.$emit("onCoverCropped", this.cropped, this.photoFile);
            this.photoFile = null;
            this.videoFile = null;
            this.cropped = null;
        },
        /**
         *
         *
         *
         *
         *
         *
         *
         *
         *
         *
         * Firebase 影片上傳
         *
         *
         *
         *
         *
         *
         *
         *
         */

        // firesbase 上傳影片
        uploadFile() {
            // Create the file metadata
            var metadata = {
                contentType: this.videoFile.type,
            };
            const filePath = `public/videos/${uuidv4()}`;
            // Upload file and metadata to the object 'images/mountains.jpg'
            var uploadTask = storage().ref().child(filePath).put(this.videoFile, metadata);

            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(
                "state_changed", // or 'state_changed'
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    this.progress = progress;
                    // console.log("Upload is " + progress + "% done");
                    // console.log(snapshot.bytesTransferred, snapshot.totalBytes);
                    // console.log(snapshot);
                    switch (snapshot.state) {
                        case "paused": // or 'paused'
                            console.log("Upload is paused");
                            break;
                        case "running": // or 'running'
                            console.log("Upload is running");
                            break;
                    }
                },
                (error) => {
                    // A full list of error codes is available at
                    // https://firebase.google.com/docs/storage/web/handle-errors
                    switch (error.code) {
                        case "storage/unauthorized":
                            // User doesn't have permission to access the object
                            break;
                        case "storage/canceled":
                            // User canceled the upload
                            break;

                        // ...

                        case "storage/unknown":
                            // Unknown error occurred, inspect error.serverResponse
                            break;
                    }
                },
                () => {
                    // Upload completed successfully, now we can get the download URL
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        console.log("File available at", downloadURL);
                        this.videolink = downloadURL;
                        this.$message({
                            type: "success",
                            message: "上傳成功",
                        });
                    });
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
    @apply flex items-center justify-center border-none;
}
::v-deep .el-upload-list {
    @apply sr-only;
}
::v-deep .el-upload-dragger {
    @apply h-auto w-full;
}
::v-deep .el-upload {
    @apply w-auto;
}
::v-deep .el-dialog {
    @screen sm {
        @apply w-[500px];
    }
    @apply w-auto;
}
</style>
